'use strict';

var app = angular.module('FSS', [
    'ui.router',
    'ui.bootstrap',
    'ngMessages',
    'ngCookies',
    'ngSanitize',
]);

app.config(['$urlMatcherFactoryProvider', '$locationProvider', '$stateProvider', '$urlRouterProvider', '$httpProvider', '$qProvider',
    function ($urlMatcherFactoryProvider, $locationProvider, $stateProvider, $urlRouterProvider, $httpProvider, $qProvider) {

        $qProvider.errorOnUnhandledRejections(false);
        $urlMatcherFactoryProvider.strictMode(false);
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
    }])
    .run(['$rootScope', '$state', 'AuthService', '$transitions', '$q', '$timeout',
        function ($rootScope, $state, AuthService, $transitions, $q, $timeout) {

            var allowDebug = false;
            $rootScope.log = (...args) => {
                if (!allowDebug) {
                    return;
                }
                let aRegexResult = (new Error().stack).split('\n');
                aRegexResult[2] = aRegexResult[2].replace("    at ", "");
                console.info("!!!DEBUG: ", aRegexResult[2], ":", ...args);
            }
            $rootScope.checkMethod = (method) => {
                if ($rootScope.globals.availableMethods){
                    var foundIndex = $rootScope.globals.availableMethods.indexOf(method);
                    return foundIndex;
                } else {
                    return -1;
                }
            }
            // METHOD TO HANDLE TOAST NOTIFICATIONS
            $rootScope.toast_notifications = [];
            $rootScope.addNotification = (notification) => {
                if(!notification) {
                    return;
                }
                // add the notification
                $rootScope.toast_notifications.unshift(notification);
                // remove it after 5 seconds
                $timeout(() => {
                    $rootScope.toast_notifications.pop();
                }, 5000);
            };
        }
    ]);

